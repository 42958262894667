import "./styles.css";

window.scrollTo(0,0);

document.addEventListener('DOMContentLoaded', (event) => {
  document.querySelector(".logo").classList.add("active");

  const elements = [...document.querySelectorAll(".paragraphs p")]
  let timer_base = 200;

  setTimeout(() => {
    for(let i = 0; i < elements.length; i++) {

      const element = elements[i];
  
      setTimeout(() => {
        element.classList.add("active");
      }, timer_base*i);
    }
  }, 500);
});